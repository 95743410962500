(function () {
	//SP
	new ViewportExtra(375)
	//TB PC
	var ua = navigator.userAgent
	var sp = ua.indexOf('iPhone') > -1 ||
		(ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)
	var tab = !sp && (
		ua.indexOf('iPad') > -1 ||
		(ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) ||
		ua.indexOf('Android') > -1
	)
	if (tab) new ViewportExtra(1280)
})()